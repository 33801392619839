@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*RESET*/

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

/*VARS*/
:root {
    /* colors */
    --black: #000000;
    --white: #ffffff;
    --red:#F24645;
    --pink:#FFECEC;
    /* border */
    --border: 1px solid var(--red);
    /* frame */
    --max-width: 1280px;
    --title-height: 34px;
    --footer-height: 174px;
    --main-height: calc(100vh - var(--title-height) - var(--footer-height) - 4px);
  }

html {
    font-weight: 600;
    font-size: 0.9rem;
    color: var(--red);
    background-color: var(--pink);
    text-align: center;
}

body {
    font-family: Roboto Mono, monospace;

}

a {
    color: var(--red);
}

a:hover {
    text-decoration: none;
}

html, body, #root, {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

body {
    background-color: var(--pink);
}

#root {
    margin: 0 auto;
}

.app-container {
    width: 100%;
    max-width: var(--max-width);
    margin: 0 auto;
}

.app {
    width: 100%;
    display: flex;
    text-align: initial;
    height: var(--main-height);
}

/* HEADER */

.title-wrapper {
    height: var(--title-height);
    width: 100%;
    background: var(--white);
}

.title {
    border: var(--border);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    z-index: 100;
}

/* LEFT */

nav {
    background: var(--white);
    border: var(--border);
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

nav header, nav a {
    border: var(--border);
    padding: 0.25em;
    background-color: initial;
    text-decoration: none;
    display: flex;
    align-content: center;
    align-items: center;
    height: var(--title-height);
    padding-left: 16px;
}

nav header {
    color: var(--white);
    background: var(--red);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    justify-content: space-around;
    z-index: 10;
}

nav span {
    pointer-events: none;
}

.address {
    position: relative;
    display: block;
    padding-left: 16px;
    display: flex;
    align-content: center;
    align-items: center;
}

.address.selected {
    background-color: var(--pink);
}

.arrow {
    display: none;
    position: absolute;
    right: 0;
    height: 100%;
    width: 40px;
    padding-left: 8px;
    border-left: 2px solid var(--red);
    background-color: var(--pink);
    color: var(--red);
    pointer-events: none;
    font-size: 18px;
    letter-spacing: -.2em;
}

.arrow.selected {
    display: flex;
    align-items: center;
}

/* RIGHT */

aside {
    background: var(--white);
    border: var(--border);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
}

aside .card-wrapper {
    margin: 5% 12%;
    max-height: 90%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    gap: 20px;
}

aside .card-wrapper span:last-child {
    margin-bottom: 1em;
}

aside .card-wrapper img {
    border: var(--border);
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

aside span p {
    text-align: center;
}

aside .card-wrapper img.grayscale {
    filter: grayscale(1);
}

.overlay-container {
    position: relative;
}

.overlay-enabled {
    position: absolute;
    width: 100%;
    height: 100%;
    border: var(--border);
    mix-blend-mode: screen;
    background-color: var(--red);
    z-index: 10;
}

.select-address {
    display: flex;
    flex-direction: column;
}

.select-address p {
    width: 220px;
    text-align: center;
    margin-top: 16px;
    line-height: 130%;
}

/* MOBILE HACKERY */

@media only screen and (max-width: 900px) {
    :root {
         --footer-height: 0px;
    }
    .app {
        flex-wrap: wrap;
    }
    aside {
        order: -1;
        height: 50%;
    }
    nav {
        height: 50%;
    }
    footer {
        display: none;
    }
    .arrow.selected {
        display: none;
    }
}

/* FOOTER */

footer {
    height: var(--footer-height);
    width: 100%;
    background: var(--white);
}

.footer-inner {
    border: var(--border);
    height: calc(100% - 3px);
}

.footerart {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.footerart__wrapper {
    height: 132px;
}

.footerart__wrapper img {
    padding-left: 32px;
    padding-right: 32px;
}

.footerart__wrapper.logo {
    border-left: var(--border);
    border-right: var(--border);
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.footerlink {
    border-top: var(--border);
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    min-height: 32px;
}

